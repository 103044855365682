import { Injectable }      from '@angular/core';
import { GenericResponse } from '@core/Interface/GenericResponse';
import { CrudDataService } from '@core/Service/CrudDataService';
import { config }          from 'src/app/config';

@Injectable({ providedIn: 'root' })
export class ContactRequestDataService extends CrudDataService {
	apiUrl = config.apiUrl + '/api/organization-contact-request';

	async accept(id: string) {
		return await this.coreHttp.makeRequest<GenericResponse>(`${ this.apiUrl }/accept`, { id: id }, 'post');
	}

	async reject(id: string) {
		return await this.coreHttp.makeRequest<GenericResponse>(`${ this.apiUrl }/reject`, { id: id }, 'post');
	}

	async sendContactRequest(organizationId: string, department: string) {
		return await this.coreHttp.makeRequest<GenericResponse>(`${ this.apiUrl }/send-contact-request`, {
			organizationId: organizationId,
			department: department
		}, 'post');
	}

}
