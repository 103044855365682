import { FieldType }  from '@class-hydrator/Decorators';
import * as moment    from 'moment';

export type ContactRequestStatus = 'new' | 'accepted' | 'rejected';

export class OrganizationContactRequest {
	id: string = null;

	inviterId: string = null;
	inviterName = '';

	memberId: string = null;
	memberName = '';

	organizationId: string = null;
	organizationName = '';

	@FieldType({ type: 'moment' })
	createdAt: moment.Moment = null;
	@FieldType({ type: 'moment' })
	acceptedAt: moment.Moment = null;
	@FieldType({ type: 'moment' })
	rejectedAt: moment.Moment = null;

	status: ContactRequestStatus = 'new';
}
